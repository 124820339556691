class HeaderPrimeKunde extends HTMLElement {
  connectedCallback() {
    this.setElements();
    this.observeAttributes();
  }

  setElements() {
    this.merkzettel = this.querySelector('.icon-merkzettel');
    this.warenkorb = this.querySelector('.icon-warenkorb');
  }

  observeAttributes() {
    new MutationObserver(this.mutationCallback).observe(this.merkzettel, { attributes: true });
    new MutationObserver(this.mutationCallback).observe(this.warenkorb, { attributes: true });
  }

  mutationCallback(mutationsList) {
    for (const mutation of mutationsList) {
      if (mutation.type === 'attributes' && mutation.attributeName === 'count') {
        const currentCount = mutation.target.getAttribute('count');
        if (currentCount != null && currentCount !== '9+' && parseInt(currentCount) > 99) {
          mutation.target.setAttribute('count', '9+');
        }
      }
    }
  }

}

if (!customElements.get('header-prime-kunde')) {
  customElements.define('header-prime-kunde', HeaderPrimeKunde);
}
